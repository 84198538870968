import { type Blueprint, createVuetify } from 'vuetify'
import { md2 } from 'vuetify/blueprints'
import { ru } from 'vuetify/locale'
import darkThemeColors from './../../config/dark-theme-tokens.json'
import lightThemeColors from './../../config/light-theme-tokens.json'
import icons from './../../config/icons.json'

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    ssr: true,
    blueprint: md2 as Blueprint,
    theme: {
      themes: {
        light: { colors: lightThemeColors },
        dark: { colors: darkThemeColors },
      },
    },
    icons: {
      aliases: icons,
    },
    locale: {
      locale: 'ru',
      messages: { ru },
    },
    defaults: {
      VImg: {
        eager: true,
      },
      VTextField: {
        color: 'primary',
        variant: 'outlined',
      },
      VBtn: {
        variant: 'flat',
      },
      VNavigationDrawer: {
        order: 0,
        location: 'right',
        tag: 'div',
      },
      VAppBar: {
        order: 1,
      },
      VFooter: {
        order: 1,
      },
      VToolbar: {
        height: 56,
        color: 'background',
      },
      VTextarea: {
        color: 'primary',
        variant: 'outlined',
      },
      VAutocomplete: {
        variant: 'outlined',
      },
      VSpacer: {
        class: 'flex-grow-1',
      },
      VTooltip: {
        location: 'bottom',
        scrollStrategy: 'close',
      },
      VChipGroup: {
        variant: 'outlined',
        color: 'primary',
      },
      VChip: {
        variant: 'outlined',
      },
    },
  })

  app.vueApp.use(vuetify)

  return {
    provide: {
      vuetify,
    },
  }
})
