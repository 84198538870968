<template>
  <client-only>
    <div
      v-if="isStage"
      class="red-line-stage"
      :class="{ 'red-line-stage_mobile': $device.isMobile }"
    >
      STAGE
    </div>
  </client-only>
</template>

<script setup lang="ts">
import { STAGE_ORIGIN_URL } from '~/constants'

const isStage = computed(() => !process.server && window.location.origin === STAGE_ORIGIN_URL)
const $device = useDevice()
</script>

<style lang="scss" scoped>
  .red-line-stage {
    background-color: $t-error;
    bottom: 0;
    color: $t-white;
    font-size: $t-font-size-xs;
    font-style: italic;
    font-weight: $t-font-weight-semi-bold;
    left: 0;
    letter-spacing: 2px;
    opacity: .96;
    padding: 8px 16px;
    pointer-events: none;
    position: fixed;
    text-align: center;
    text-transform: uppercase;
    width: 100vw;
    z-index: 9999999;

    &_mobile {
      bottom: auto;
      font-size: 0;
      padding: 4px;
      top: 0;
    }
  }
</style>
