<template>
  <v-app :theme="themeName">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <RedLineStage />
    <MainSnackbar />
  </v-app>
</template>

<script setup lang="ts">
const $route = useRoute()
const themeName = computed(() => $route.fullPath.includes('grand-prix') ? 'dark' : 'light')
</script>
