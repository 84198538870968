const resource = '/me/'

export interface MeGetResponse {
  award_years: Array<number>
  grand_prix_years: Array<number>
  feature_flags: Record<string, boolean>
  site_settings: Record<string, boolean>
}

export default class MeApi {
  private readonly axios = axiosClient

  get(): Promise<MeGetResponse> {
    return this.axios.$get(`${resource}`)
  }
}
